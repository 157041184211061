<template>
    <!-- <div class="section_title mb-4">{{title}}
        <div class="dropdown">
            <i class="fas fa-ellipsis-v" @click="noteOption = !noteOption"  v-click-outside="closeNoteOption"></i>
            <div class="drp_wrapper" v-if="noteOption" :class="{active : noteOption}">
                <ul>
                    <li @click="templateLibrary = !templateLibrary" :class="{active : templateLibrary}">Import Template</li>
                </ul>
            </div>
        </div>
    </div> -->
    <Form @submit="handleEditNote" v-slot="{ errors }" class="note_form">
        <div class="editorWpr">
            <div class="editorWpr" :class="{ 'has-error': errors.notes }">
                <Field autocomplete="off" name="notes" v-model="form.notes" :class="{ 'has-error': errors.notes }" rules="required">
                    <redactor  v-model="form.notes" name="notes" :default-value="editorDefaultValue" :reset-watcher="resetToDefaultWatcher" :has-import-template="true" :import-template-handler="toggleTemplateLibrary"/>
                </Field>
            </div>
            <div class="err_message">
                <ErrorMessage name="notes" class="text-danger" />
            </div>
        </div>
        <div v-if="templateLibrary">
            <template-library v-model="templateLibrary" :on-import="handleTemplateImport" type="content" />
        </div>
        <div class="action_wpr mt-4 mb-0 p-0">
            <button :disabled="noteLoader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
            <button :disabled="noteLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="noteLoader"></i>{{ noteLoader ? ' Saving' : 'Save' }}</button>
        </div>
    </Form>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const TemplateLibrary = defineAsyncComponent(() => import('@/components/TemplateLibrary'))

    export default {
        name: 'Edit Card',

        data(){
            return{
                noteOption: false,
                templateType: '',
                templateLibrary: false,
                form: {
                    contact_id: '',
                    id: '',
                    notes: '',
                },
                editorDefaultValue: '',
                resetToDefaultWatcher: 0,
            }
        },

        props:{
            closeModal: Function,
            title: String,
            note: Object
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            TemplateLibrary,
        },

        computed: {
            ...mapState({
                noteLoader: state => state.contactModule.noteLoader,
            }),
        },

        mounted () {
            const vm = this;

            vm.form = {
                contact_id: vm.note.contact_id,
                id: vm.note.id,
                notes: vm.note.notes,
            }

            vm.editorDefaultValue = vm.note.notes;
        },

        methods: {
            ...mapActions({
                updateNote: 'contactModule/updateNote',
                getNotes: 'contactModule/getNotes',
            }),

            handleTemplateImport (template) {
                const vm = this;

                vm.form.notes = template.email_content;
                vm.editorDefaultValue = template.email_content;
            },

            handleEditNote (form, { setFieldError }) {
                const vm     = this;
                const params = vm.$parent.$parent && vm.$parent.$parent.params ? vm.$parent.$parent.params : (vm.$parent.$parent && vm.$parent.$parent.noteParams ? vm.$parent.$parent.noteParams : vm.$parent.noteParams);

                vm.form.setFieldError = setFieldError;

                vm.updateNote(vm.form).then((result) => {
                    if (result) {
                        vm.resetToDefaultWatcher += 1;
                        vm.getNotes(params);
                        vm.closeModal();
                    }
                });
            },

            closeNoteOption () {
                const vm     = this;

                vm.noteOption = false;
            },

            toggleTemplateLibrary(){
                const vm = this;

                vm.templateLibrary = !vm.templateLibrary;
            },
        }
    }
</script>

<style scoped>
    .note_form .edit_section{
        background: #fafafb;
        padding: 20px;
    }
    .global_setting .note_form .editorWpr :deep(.redactor-styles){
        height: 200px;
    }
    .global_setting .note_form .action_wpr{
        background: transparent;
        border: 0;
    }
</style>
